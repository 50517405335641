import { atom, useAtom } from "jotai";
import { MutableRefObject, useEffect } from "react";
import { introRef } from "../../components/organisms/LandingPageOranisms/LandingPageIntroOrganism/LandingPageIntroOrganism";
import { reviewRef } from "../../components/organisms/LandingPageOranisms/LandingPageFourthBodyOrganism/LandingPageFourthBodyOrganism";
import { partnerRef } from "../../components/organisms/LandingPageOranisms/LandingPageBestPartnersOrganism/LandingPageBestPartnersOrganism";
import { curriculumRef } from "../../components/organisms/LandingPageOranisms/LandingPageCurriculumOrganism/LandingPageCurriculumOrganism";
import { applyRef } from "../../components/organisms/LandingPageOranisms/LandingPageApplyOraganism/LandingPageApplyOraganism";
import { scrollYAtom } from "../util-browser";

export enum sectionId {
  intro = "소개",
  review = "후기",
  partner = "협력사",
  curriculum = "커리큘럼",
  schedule = "모집일정",
}

export const useActiveSection = () => {
  const [scrollY] = useAtom(scrollYAtom);
  const [activeSection, updateActiveSection] = useAtom(updateActiveSectionAtom);
  useEffect(() => {
    updateActiveSection(Object.keys(sectionId));
  }, [scrollY]);

  return activeSection;
};

export const useOffsetTopList = () => {
  const [offsetTopList, updateOffsetTopList] = useAtom(updateOffsetTopListAtom);
  useEffect(() => {
    const handleResize = () => {
      const sectionElemList = [
        introRef,
        reviewRef,
        partnerRef,
        curriculumRef,
        applyRef,
      ];
      updateOffsetTopList(sectionElemList);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return offsetTopList;
};

type OffsetTopList = number[];
export type Section =
  | "intro"
  | "review"
  | "partner"
  | "curriculum"
  | "schedule";

type SectionRefList = SectionRef[];

const getActiveSection = (
  currentY: number,
  sectionOffsetTopList: OffsetTopList,
  sectionList: string[]
): Section => {
  sectionOffsetTopList[sectionList.length] = Infinity;
  const index =
    sectionOffsetTopList
      .slice()
      .findIndex((offsetTop) => currentY < offsetTop) - 1;
  return <Section>sectionList[index];
};

export const offsetTopListAtom = atom<OffsetTopList>([0, 1, 2, 3, 4]);
export const activeSectionAtom = atom<Section>("intro");
export type SectionRef = MutableRefObject<any>;

const mobileNavHeight = 36;

const getOffsetTop = (ref: SectionRef): number => {
  return ref && ref.current ? ref.current.offsetTop - mobileNavHeight : 0;
};
const getOffsetTopList = (refs: SectionRefList): number[] => {
  return refs.map((ref) => getOffsetTop(ref));
};

export const updateActiveSectionAtom = atom(
  (get) => get(activeSectionAtom),
  (get, set, sectionList: string[]) => {
    set(
      activeSectionAtom,
      getActiveSection(get(scrollYAtom), get(offsetTopListAtom), sectionList)
    );
  }
);

export const updateOffsetTopListAtom = atom(
  (get) => get(offsetTopListAtom),
  (get, set, sectionRefList: SectionRefList) => {
    set(offsetTopListAtom, getOffsetTopList(sectionRefList.sort()));
  }
);
