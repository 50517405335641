import * as S from "./LandingPageCurriculumOrganism.style";
import Image from "next/image";
import checkIcon from "/static/images/icons/icons-check.png";
import {
  landingCurriculumCheckData,
  landingCurriculumGraphData,
  landingCurriculumNavData,
} from "../../../../models/landing/landing.models";
import { Fragment, MutableRefObject, useRef, useState } from "react";
import arrow_gray from "/static/images/icons/ic_arrow.png";
import arrow_red from "/static/images/icons/ic_arrow_open.png";
import { useInnerWidth } from "../../../../businessLogics/util-browser";

export let curriculumRef: MutableRefObject<any>;
export default function LandingPageCurriculumOrganism() {
  curriculumRef = useRef(null);
  const [selectedWeek, setSelectedWeek] = useState(0);
  const [isChapterOpen, setIsChapterOpen] = useState(true);
  const innerWidth = useInnerWidth();
  const mobileChapter = landingCurriculumGraphData.find(
    (week) => week.index === selectedWeek
  );
  const onClickChapter = (i) => {
    if (i === selectedWeek) {
      setSelectedWeek(-1);
      return;
    }
    setSelectedWeek(-1);
    setSelectedWeek(i);
  };

  return (
    <S.Wrapper id="커리큘럼" ref={curriculumRef}>
      <S.Contents>
        <S.Title>커리큘럼</S.Title>
        <S.CheckPointWrapper>
          {landingCurriculumCheckData.map((check) => (
            <S.CheckPoint key={check}>
              <S.CheckIcon>
                <Image src={checkIcon} layout="fill" objectFit="contain" />
              </S.CheckIcon>
              <S.Text>{check}</S.Text>
            </S.CheckPoint>
          ))}
        </S.CheckPointWrapper>
        {innerWidth > 1024 ? (
          <S.GraphWrapper>
            <S.GraphNav>
              {landingCurriculumNavData.map((nav, i) => (
                <S.NavWrapper key={i} onClick={() => setSelectedWeek(i)}>
                  <S.Nav week={selectedWeek} index={i}>
                    <S.Week week={selectedWeek} index={i}>
                      {nav.week}
                    </S.Week>
                    <S.Chapter week={selectedWeek} index={i}>
                      {nav.chapter}
                    </S.Chapter>
                  </S.Nav>
                  <S.Arrow week={selectedWeek} index={i}>
                    <Image
                      src={selectedWeek === i ? arrow_red : arrow_gray}
                      layout="fill"
                      objectFit="contain"
                    />
                  </S.Arrow>
                  <S.Hr week={selectedWeek} index={i} />
                </S.NavWrapper>
              ))}
            </S.GraphNav>
            {/*<S.Graph>*/}
            {landingCurriculumGraphData.map((week, i) => {
              return (
                selectedWeek === i && (
                  <S.Graph key={i}>
                    <S.Header>
                      <S.ChapterWeek>{week.week}</S.ChapterWeek>
                      <S.ChapterInfo>
                        <S.Weeks type={week.button1}>{week.button1}</S.Weeks>
                        <S.Weeks type={week.button2}>{week.button2}</S.Weeks>
                      </S.ChapterInfo>
                    </S.Header>
                    <S.WeekImg>
                      <Image src={week.img} layout="fill" objectFit="contain" />
                    </S.WeekImg>
                    <S.ChapterDesc>
                      {week.desc.map((desc) => (
                        <S.Desc
                          dangerouslySetInnerHTML={{ __html: desc }}
                          key={desc}
                        />
                      ))}
                    </S.ChapterDesc>
                  </S.Graph>
                )
              );
            })}
            {/*</S.Graph>*/}
          </S.GraphWrapper>
        ) : (
          //mobile
          <S.GraphWrapper>
            {/*<S.GraphNav>*/}
            {landingCurriculumNavData.map((nav, i) => (
              <S.GraphNav key={i}>
                <S.NavWrapper onClick={() => setSelectedWeek(i)}>
                  <S.Nav week={selectedWeek} index={i}>
                    <S.Week week={selectedWeek} index={i}>
                      {nav.week}
                    </S.Week>
                    <S.Chapter week={selectedWeek} index={i}>
                      {nav.chapter}
                    </S.Chapter>
                  </S.Nav>
                  <S.Arrow week={selectedWeek} index={i}>
                    <Image
                      src={selectedWeek === i ? arrow_red : arrow_gray}
                      layout="fill"
                      objectFit="contain"
                    />
                  </S.Arrow>
                  <S.Hr week={selectedWeek} index={i} />
                </S.NavWrapper>
                {isChapterOpen && i === mobileChapter.index ? (
                  <S.Graph mapIndex={i} dataIndex={mobileChapter.index}>
                    <S.Header>
                      <S.ChapterWeek>{mobileChapter.week}</S.ChapterWeek>
                      <S.ChapterInfo>
                        <S.Weeks type={mobileChapter.button1}>
                          {mobileChapter.button1}
                        </S.Weeks>
                        <S.Weeks type={mobileChapter.button2}>
                          {mobileChapter.button2}
                        </S.Weeks>
                      </S.ChapterInfo>
                    </S.Header>
                    <S.WeekImg>
                      <Image
                        src={mobileChapter.img}
                        layout="fill"
                        objectFit="contain"
                      />
                    </S.WeekImg>
                    <S.ChapterDesc>
                      {mobileChapter.desc.map((desc) => (
                        <S.Desc
                          dangerouslySetInnerHTML={{ __html: desc }}
                          key={desc}
                        />
                      ))}
                    </S.ChapterDesc>
                  </S.Graph>
                ) : (
                  ""
                )}
              </S.GraphNav>
            ))}
            {/*</S.GraphNav>*/}
          </S.GraphWrapper>
        )}
      </S.Contents>
    </S.Wrapper>
  );
}
