const size = {
  mobile: 320,
  tablet: 768,
  desktop: 1024,
};

export const device = {
  mobile: `(min-width: ${size.mobile}px)`,
  tablet: `(min-width: ${size.tablet}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
  lessThanDesktop: `(max-width: ${size.desktop - 1}px)`,
};

export const DESKTOP = `@media ${device.desktop}`;
