import * as S from "./LandingPageApplyRounds.style";
import { useRecruitingRounds } from "../../../../queries/application";
import {
  getMoDateDay,
  goToApply,
} from "../../../../businessLogics/application";
import { useCountdown } from "../../../../businessLogics/homework";
import Timer from "../LandingPageTimer";
import { useRouter } from "next/router";
import { Fragment } from "react";

export default function LandingPageApplyRounds() {
  const router = useRouter();
  const { data: rounds } = useRecruitingRounds();

  return (
    <>
      {rounds &&
        rounds.map((round) => {
          return (
            <Fragment key={round.id}>
              <S.FirstApplyRound>
                <S.RoundInfo>
                  <S.Round>{round.name} 모집 중</S.Round>
                  <S.RoundDetails>
                    <S.RoundDetail>
                      <S.DetailTitle>신청 접수</S.DetailTitle>
                      <S.DetailDesc>
                        {getMoDateDay(round.enrollEndDate)} 까지
                      </S.DetailDesc>
                    </S.RoundDetail>
                    <S.RoundDetail>
                      <S.DetailTitle>항해 기간</S.DetailTitle>
                      <S.DetailDesc>
                        {getMoDateDay(round.startDate)} ~{" "}
                        {getMoDateDay(round.endDate)}{" "}
                      </S.DetailDesc>
                    </S.RoundDetail>
                  </S.RoundDetails>
                </S.RoundInfo>
                <S.FirstRoundApply>
                  <S.ApplyButton onClick={() => goToApply(router, round.id)}>
                    {round.name} 참가 신청하기
                  </S.ApplyButton>
                </S.FirstRoundApply>
                <S.FirstApplyDue>
                  {round.name === "10기" ? (
                    <S.Text>
                      지금 합류 시, <strong>사전스터디 혜택</strong> 제공
                    </S.Text>
                  ) : (
                    <S.Text></S.Text>
                  )}
                  {/* <S.Text>{round.name} 마감까지 </S.Text>
                  <Timer enrollEndDate={round.enrollEndDate} /> */}
                </S.FirstApplyDue>
              </S.FirstApplyRound>
            </Fragment>
          );
        })}
    </>
  );
}
