import { MutableRefObject, useRef, useState } from "react";
import Image from "next/image";
import * as S from "./LandingPageFourthBodyOrganism.style";
import {
  CarouselNextArrowPathProps,
  CarouselNextArrowSVGProps,
  CarouselPrevArrowPathProps,
  CarouselPrevArrowSVGProps,
  landingInterviewData,
} from "../../../../models/landing/landing.models";
import arrowNextBlack from "../../../../../static/images/icons/arrow_next_black.svg";
import { useInnerWidth } from "../../../../businessLogics/util-browser";

export let reviewRef: MutableRefObject<any>;
export default function LandingPageFourthBodyOrganism() {
  reviewRef = useRef(null);
  const [slideCount, setSlideCount] = useState(0);
  const innerWidth = useInnerWidth();
  const maximumSlideNum = landingInterviewData.length - 3;
  const isPrevActive = slideCount > 0;
  const isNextActive = slideCount < maximumSlideNum;
  const moveCarousel = (isNextDirection: boolean) => {
    if (isNextDirection) {
      setSlideCount(slideCount + 1);
    } else {
      setSlideCount(slideCount - 1);
    }
  };

  const handleClickPrevArrow = () => {
    if (isPrevActive) {
      moveCarousel(false);
    }
  };

  const handleClickNextArrow = () => {
    if (isNextActive) {
      moveCarousel(true);
    }
  };

  const getReviewsStyle = () => {
    return { left: -(slideCount * 358) };
  };

  return (
    <S.Wrapper id="후기" ref={reviewRef}>
      <S.Contents>
        <S.Title>
          실제로, <br />
          개발자로 거듭났습니다.
        </S.Title>
        <S.Carousel>
          <S.Arrow isActive={isPrevActive} onClick={handleClickPrevArrow}>
            <S.ArrowSVG {...CarouselPrevArrowSVGProps}>
              <S.ArrowPath
                isActive={isPrevActive}
                {...CarouselPrevArrowPathProps}
              />
            </S.ArrowSVG>
          </S.Arrow>
          <S.InterviewWrapper>
            <S.Interviews style={getReviewsStyle()}>
              {landingInterviewData.map((interview) => (
                <S.Interview key={interview.url}>
                  <iframe
                    src={interview.url}
                    width={innerWidth < 1024 ? 264 : 332}
                    height={innerWidth < 1024 ? 148 : 187}
                    frameBorder={0}
                    allowFullScreen={true}
                    loading="lazy"
                  />
                  <h3>{interview.title}</h3>
                  <p>{interview.desc}</p>
                  <div>
                    <button
                      onClick={() =>
                        window.open(`${interview.buttonUrl}`, "_blank")
                      }
                    >
                      {interview.button}
                      <Image src={arrowNextBlack} width={20} height={20} />
                    </button>
                  </div>
                </S.Interview>
              ))}
            </S.Interviews>
          </S.InterviewWrapper>
          <S.Arrow isActive={isNextActive} onClick={handleClickNextArrow}>
            <S.ArrowSVG {...CarouselNextArrowSVGProps}>
              <S.ArrowPath
                isActive={isNextActive}
                {...CarouselNextArrowPathProps}
              />
            </S.ArrowSVG>
          </S.Arrow>
        </S.Carousel>
      </S.Contents>
    </S.Wrapper>
  );
}
