import * as S from "./LandingPageIntroOrganism.style";
import Image from "next/image";
import { useInnerWidth } from "../../../../businessLogics/util-browser";
import { goToApply } from "../../../../businessLogics/application";
import { useRouter } from "next/router";
import { MutableRefObject, useRef } from "react";
export let introRef: MutableRefObject<any>;

export default function LandingPageIntroOrganism() {
  const router = useRouter();
  introRef = useRef(null);
  return (
    <S.Wrapper ref={introRef}>
      <S.BackgroundImg>
        <Image
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          priority={true}
          src={`${process.env.CDN_BASE_URL}/hanghae99/hero.jpg`}
        />
      </S.BackgroundImg>
      <S.Intro>
        <S.IntroContents>
          <S.IntroTitle>
            99일간의 항해로, <br />
            개발자로 거듭나세요.
          </S.IntroTitle>
          <S.IntroDesc>
            주특기 하나를 집요하게 파고들어 숙달하고,
            <br />
            실제 고객까지 있는 실전 프로젝트를 진행합니다.
          </S.IntroDesc>
          <S.IntroButton onClick={() => goToApply(router)}>
            참가 신청하기
          </S.IntroButton>
        </S.IntroContents>
      </S.Intro>
    </S.Wrapper>
  );
}
