import styled from "@emotion/styled";
import { device } from "../../../../../styles/themes";
import { hhMainColor } from "../../../../../styles/themes/variables";

export const Wrapper = styled.section`
  background-color: #f1f1f1;
  padding: 40px 0;
  @media ${device.desktop} {
    padding: 60px 0;
  }
`;

export const Contents = styled.div`
  margin: 0 auto;
  max-width: 320px;
  text-align: center;
  @media ${device.desktop} {
    max-width: 1230px;
  }
`;

export const Title = styled.h2`
  font-size: 22px;
  line-height: 1.45;
  letter-spacing: 0.66px;
  margin: 0;
  @media ${device.desktop} {
    font-size: 31px;
    letter-spacing: 1px;
  }
`;

export const Desc = styled.p`
  margin: 0 auto 5px;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.42px;
  color: #696969;
  font-weight: 300;
  @media ${device.desktop} {
    font-size: 16px;
    letter-spacing: -1.12px;
    color: #212529;
    margin: 0;
    padding-bottom: 50px;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const ApplyInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ApplyRounds = styled.div`
  @media ${device.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 21px;
  }
`;

export const ApplyInfo = styled.div`
  border-radius: 4px;
  margin: 0 auto;
  width: 320px;
  padding: 32px 17px 15px 24px;
  box-shadow: 0 1px 25px 0 rgb(0 0 0 / 10%);
  background-color: #ffffff;
  @media ${device.desktop} {
    width: 100%;
    padding: 50px 0 45px;
    box-shadow: 0 1px 25px 0 rgb(0 0 0 / 6%);
    display: grid;
    grid-template-columns: 1.2fr 1fr;
  }
`;

export const LeftInfo = styled.div`
  @media ${device.desktop} {
    width: 475px;
    margin-left: 97px;
  }
`;

export const Header = styled.div`
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: bold;
  text-align: left;
  @media ${device.desktop} {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

export const ProcessDescWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const ProcessDesc = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

export const DescDetail = styled.span`
  font-size: 13.1px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.66px;
  text-align: left;
  color: #212529;
  display: inline-block;
  margin-left: 10px;
  @media ${device.desktop} {
    font-size: 16px;
    letter-spacing: -0.8px;
    margin-left: 20px;
  }
`;

export const Requirement = styled.div`
  margin-top: 15px;
  @media ${device.desktop} {
    margin-top: 40px;
  }
`;

export const NarrowHeader = styled(Header)`
  @media ${device.desktop} {
    letter-spacing: -0.48px;
  }
`;

export const ListWrapper = styled.ul`
  list-style-type: disc;
  padding-inline-start: 20px;
`;

export const List = styled.li`
  font-size: 14px;
  font-weight: 200;
  line-height: 1.6;
  letter-spacing: -0.42px;
  text-align: left;
  color: #212529;
  @media ${device.desktop} {
    font-size: 16px;
    letter-spacing: -1.12px;
  }
`;

export const RightInfo = styled.div`
  margin-top: 25px;
  @media ${device.desktop} {
    margin: 0 0 0 60px;
  }
`;

export const RightListWrapper = styled(ListWrapper)`
  padding-inline-start: 18px;
`;

export const RightLists = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  @media ${device.desktop} {
    margin-bottom: 25px;
  }
`;

export const RightList = styled.li`
  font-size: 14px;
  font-weight: 400;
  @media ${device.desktop} {
    font-size: 16px;
  }
`;

export const RightListDesc = styled.p`
  margin: 5px 0 0;
  font-size: 14px;
  font-weight: 200;
  line-height: 1.6;
  letter-spacing: -0.42px;
  @media ${device.desktop} {
    font-size: 16px;
    white-space: pre-wrap;
  }
`;
