import { youtubeUrlConverter } from "../../businessLogics/utils/_helper";
import finda from "/static/images/landing/company_logo/finda-logo.png";
import megazone from "/static/images/landing/company_logo/megazonecloud-logo.png";
import yeogi from "/static/images/landing/company_logo/yeogi-logo.png";
import hyundai from "/static/images/landing/company_logo/hyundai_autoever-logo.png";
import taling from "/static/images/landing/company_logo/taling-logo.png";
import cafe24 from "/static/images/landing/company_logo/cafe24-logo.png";
import balaan from "/static/images/landing/company_logo/balaan-logo.png";
import alpaca from "/static/images/landing/company_logo/alpaca-logo.png";
import tutoring from "/static/images/landing/company_logo/tutoring-logo.png";
import newplay from "/static/images/landing/company_logo/newploy-logo.png";
import sparta from "/static/images/tutors/sparta_logo.png";
import sparta_mentor from "/static/images/tutors/byeongkwan.png";
import ecubelabs from "/static/images/tutors/ecube_logo.png";
import ecube_mentor from "/static/images/tutors/seunghyun.png";
import sleek from "/static/images/tutors/sleek_logo.png";
import sleek_mentor from "/static/images/tutors/minyeong.png";
import ecommerce_mentor from "/static/images/tutors/tutor1.png";
import baemin_mentor from "/static/images/tutors/tutor3.png";
import oneul from "/static/images/tutors/todayhouse_logo.png";
import oneul_mentor from "/static/images/tutors/seokbum.png";
import sap from "/static/images/tutors/sap_logo.png";
import sap_mentor from "/static/images/tutors/myeongin.png";
import james_mentor from "/static/images/tutors/tutor2.png";
import timeline0 from "/static/images/landing/timeline/timeline0.svg";
import timeline1 from "/static/images/landing/timeline/timeline1.svg";
import timeline2 from "/static/images/landing/timeline/timeline2.svg";
import timeline3 from "/static/images/landing/timeline/timeline3.svg";
import timeline4 from "/static/images/landing/timeline/timeline4.svg";
import timeline5 from "/static/images/landing/timeline/timeline5.svg";
import timeline6 from "/static/images/landing/timeline/timeline6.svg";
import timeline7 from "/static/images/landing/timeline/timeline7.svg";
import timeline8 from "/static/images/landing/timeline/timeline8.svg";
import clock from "/static/images/icons/ic_clock.png";
import ship from "/static/images/icons/ic_ship.png";
import calendar from "/static/images/icons/ic_calendar.png";
import online from "/static/images/icons/ic_online.png";

export const preApplyBenefits = [
  {
    iconUrl: `${process.env.CDN_BASE_URL}/hanghae99/benefit1_new.svg`,
    title: "기초 강의 4종",
    desc: `웹개발 종합반, 자바 문법,\n자바스크립트 문법, 파이썬 문법`,
  },
  {
    iconUrl: `${process.env.CDN_BASE_URL}/hanghae99/benefit2.png`,
    title: "웹개발 스터디",
    desc: "현직 개발자와 Q&A, 매니저의\n진도 체크, 팀 정원 4-6명",
  },
  {
    iconUrl: `${process.env.CDN_BASE_URL}/hanghae99/benefit3.svg`,
    title: "토이 프로젝트",
    desc: "웹 서비스 배포,\n팀 정원 4-6명",
  },
];

export const landingFirstBodyData = [
  "항해99는 코딩에 대한 사전지식이 없는 성인을 대상으로, 99일 간의 팀 프로젝트 기반의 자기주도적 성장을 통해 현업에 바로 투입 가능한 주니어 개발자를 양성하는 온라인 코딩 부트캠프입니다.",
  "훌륭한 IT회사들은 어떤 주니어 개발자를 원할까요? 저희는 능숙한 주특기를 가지고 문제를 파고드는 집요함, 새로운 기술에 대한 끊임없는 호기심, 원활하게 협업할 수 있는 겸허함, 고객의 편의를 생각하는 마음을 갖춘 개발자일 것이라고 생각합니다.",
  "99일간의 항해로, 훌륭한 개발자로 성장해나갈 수 있는 기반을 닦으세요. 때론 높은 파도가 치겠지만, 거친 바다를 헤쳐나간 경험이 소중한 자산으로 남을 것입니다.",
];

export const landingSecondBodyData = [
  "기초적인 풀스택 기술과 필수적인 컴퓨터공학 지식을 배워 지속적인 성장의 기반을 다지고,\n선택한 기술 스택 하나를 주특기로 삼아, 14주 내내 갈고닦습니다.",
  "미니 프로젝트 2회, 클론코딩 1회, 실제 서비스 런칭까지. 디자이너와 협업하고\n마케팅을 집행하며 실제 고객을 유치하고, 서비스를 개선합니다.",
  "성장하는 동료들이 가득한 회사로 당신의 미래를 이끌어보세요.",
];

export const landingThirdBodyData = [
  {
    img: `${process.env.CDN_BASE_URL}/hanghae99/str1-min.png`,
    title: "주특기를 가진 T자형 개발자",
    desc: "프론트, 백엔드 모집 공고가 풀스택 모집 공고보다 28배 많습니다. 결국 현업에서는 하나를 깊게 다루는 개발자를 원합니다.\n항해에서는 기초적인 풀스택 기술을 익힌 후 프론트엔드(React), 백엔드(Spring 또는 Node.js)를 선택해 주특기를 집중적으로 연마합니다.",
  },
  {
    img: `${process.env.CDN_BASE_URL}/hanghae99/str2-min.png`,
    title: "실제 서비스를 운영해 본 개발자",
    desc: "현업에서의 개발은 고객의 목소리를 바탕으로 한 지속적인 개선이 핵심입니다.\n항해에서는 이러한 경험을 미리 할 수 있도록, 디자이너와 협업하여 서비스를 런칭하고, 퍼포먼스 마케팅을 직접 진행합니다. 실제 고객이 있는 서비스, 살아숨쉬는 제품을 운영한 개발자가 되세요.",
  },
  {
    img: `${process.env.CDN_BASE_URL}/hanghae99/str3-min.png`,
    title: "팀 플레이어인 개발자",
    desc: "실제 기업들은 모두 협업을 바탕으로 개발하기에, 팀으로 일할 수 있는 사람인가는 채용에서 중요한 고려 요소입니다.\n항해에서는 4개의 프로젝트를 진행하며 최소 15명 이상의 사람과 협업합니다. 이 경험을 통해 협업 능력을 얻고 함께 일하고 싶은 개발자로 성장합니다.",
  },
];

export const landingFirstBannerData = [
  {
    per: "81.43%",
    title: "수료 3개월 내 취업률",
    nonMajor: "비전공자 81.36%",
  },
  {
    per: "92.86%",
    title: "수료 6개월 내 취업률",
    nonMajor: "비전공자 94.23%",
  },
];

export const landingInterviewData = [
  {
    url: youtubeUrlConverter("https://www.youtube.com/watch?v=ozXNY_NuHfI"),
    title: "병원 행정직에서 개발자로\n커리어 전환에 성공한 정석진님",
    desc: `“항해99 수료 후 수료생 피드백 때 아쉬운 점을 좀 남겨놨는데, 지금 보니 다 반영되어 있더라고요.”`,
    button: `개발일지 보러가기`,
    buttonUrl: "https://github.com/strong1133/hh99_clone",
  },
  {
    url: youtubeUrlConverter("https://www.youtube.com/watch?v=7Vf64vYeSwo"),
    title: "공기업에서 개발자로\n새로운 도전에 성공한 고미송님",
    desc: `“안정적인 환경을 보장받기 보다 개인의 성장이 기회로 이어지는 개발 분야로 전향을 결심했고, 새로운 것을 도전하는 적극적인 태도를 갖게 됐습니다.”`,
    button: `취업회고 보러가기`,
    buttonUrl: "https://minigomi.tistory.com/47",
  },
  {
    url: youtubeUrlConverter("https://www.youtube.com/watch?v=tDykxdHYUeY"),
    title: "수료 3주 만에 개발자로\n커리어를 시작한 원동환님",
    desc: `"코딩 공부 독학할 땐 어떻게 공부해야 할지 막막하더라고요. 그래서 부트캠프 항해를 선택했습니다.”`,
    button: `인터뷰 전문 보기`,
    buttonUrl: "https://spartacodingclub.kr/blog/hanghae99-interview-1022",
  },
  {
    url: youtubeUrlConverter("https://www.youtube.com/watch?v=9g2RZza6yi0"),
    title: "명문대 문과생에서\n개발자로 거듭난 김정현님",
    desc: `“비전공자로서 기간적, 가격적인 면으로 볼 때 항해99가 빠르고 밀도있게 개발자로 거듭날 수 있는 방법이라고 생각해서 합류했어요.”`,
    button: `인터뷰 전문 보기`,
    buttonUrl: "https://www.youtube.com/watch?v=9g2RZza6yi0",
  },
  {
    url: youtubeUrlConverter("https://www.youtube.com/watch?v=gw3Zlc_2jek"),
    title: "비전공자 대학생에서 개발자로\n커리어를 시작한 송하영님",
    desc: `"제가 항해를 통해 배운 가장 큰 것 중에 하나는 어떤 어려운 문제를 만났을 때도 겁먹지 않고 계속 깊이 보려는 습관이 생겼습니다.”`,
    button: `인터뷰 전문 보기`,
    buttonUrl: "https://spartacodingclub.kr/blog/hanghae99-interview-1028",
  },
  {
    url: youtubeUrlConverter("https://www.youtube.com/watch?v=ExENhMjEjT0"),
    title: "정비사에서 개발자로\n이직에 성공한 임다빈님",
    desc: `“공부한 만큼 끊임없이 성장할 수 있는 보람된 직업이라고 생각해서 개발자가 되고자 결심했습니다.”`,
    button: `인터뷰 전문 보기`,
    buttonUrl: "https://github.com/DabinLim/Today-I-Learned",
  },
  {
    url: youtubeUrlConverter("https://www.youtube.com/watch?v=ixNgkjN3auo"),
    title: "대학원생에서 개발자로\n커리어를 시작한 금교석님",
    desc: `“9 to 9을 넘어서 새벽까지 공부를 했었는데요. 같이 하는 팀원들이 있었기 때문에 가능했습니다.”`,
    button: `개발일지 보러가기`,
    buttonUrl: "https://just-can-doit.tistory.com/17?category=846023",
  },
  {
    url: youtubeUrlConverter("https://www.youtube.com/watch?v=KVzWc4HM5hk"),
    title: "해외 대학 다니다 개발자로\n커리어를 시작한 이대호님",
    desc: `“항해에서 협력사와 커넥션을 주셔서 쉽게 지원할 수 있었고요 최종적으로 합격해서 취업을 하게 되었습니다.”`,
    button: `개발일지 보러가기`,
    buttonUrl: "https://leedaeho1188.tistory.com/m/26",
  },
  {
    url: youtubeUrlConverter(
      "https://www.youtube.com/watch?v=q-tmjFOTdEo&t=1s"
    ),
    title: "디자이너에서 개발자로\n커리어 전환에 성공한 김형민님",
    desc: `“무모하게 백지상태로 시작했지만, 해냈습니다. 절대적으로 시간을 투자하니 안 되는게 없더라구요.”`,
    button: `개발일지 보러가기`,
    buttonUrl:
      "https://hmk1022.tistory.com/category/%ED%95%AD%ED%95%B4%2099%20%ED%9A%8C%EA%B3%A0%EB%A1%9D?page=1",
  },
];

export const LandingCompanyRecommend = [
  {
    url: youtubeUrlConverter("https://youtube.com/embed/jlm9rqOB3D4"),
    title: "협력사 인터뷰\n" + "두브레인 CEO 최예진님",
    desc: `“내가 하는 일이 얼마나 큰 문제를 푸는가?를 기준으로 인생의 방향을 선택한다면 세상이 어떻게 바뀔까요?”`,
  },
  {
    url: youtubeUrlConverter("https://youtube.com/embed/8PwhNXbDip8"),
    title: "키토테이블 CEO 서용훈님,\n" + "수료생 강태진님",
    desc: `“채용자로서 ‘100일을 하드하게 한다고 될까?’란 의구심이 있었지만, 프론트/백/디자이너가 협업한 서비스를 보고, 바로 현업에서 협업할 수 있겠다는 기대감이 생겼습니다.”`,
  },
  {
    url: youtubeUrlConverter("https://www.youtube.com/embed/o4DYWE9dGXU"),
    title: "시니어 개발자가 바라본 항해\n" + "네이버 iOS 개발자 서동일님 외",
    desc: `“디자이너를 실무에 접하기 전에 만난다는 그 과정이 굉장히 특별하다고 생각이 들었습니다.”`,
  },
  {
    url: youtubeUrlConverter("https://www.youtube.com/embed/6ldCVWAGHK8"),
    title: "창업가가 바라본 항해\n" + "핵클 (前 쿠팡 PO) 김종민님 외",
    desc: `"회사에서도 이런 구조대로 신입분들을 교육해드리는데, 이정도까지 해주신다고 하면 모든 회사들이 공통적으로 좋아할 것 같아요.”`,
  },
  {
    url: youtubeUrlConverter("https://www.youtube.com/embed/vCALcaHFrAE"),
    title: "현업 개발자가 바라본 항해\n" + "직방 백엔드 파드리드 김범준님 외",
    desc: `"회사에 들어가면 팀워크가 항상 기본인데, 그걸 미리 경험해볼 수 있어서 좋은 것 같아요.”`,
  },
];

export const RecommendCardContent = [
  {
    Description:
      "실제 필드에서 CS 기초지식, 프로젝트 코워킹 경험, 실제 런칭 경험까지 모두 탄탄하게 가지고 있는 주니어 개발자는 흔치 않습니다. 스파르타 코딩클럽에서 보여준 뛰어난 팀 스파르타의 교육 컨텐츠와 훌륭한 개발자가 되고자 하는 여러분의 열정이 합쳐진다면 충분히 성공적인 개발자 커리어를 시작하실 수 있을 겁니다.",
    Title: "변치호 | beNX (빅히트 엔터테인먼트 플랫폼 자회사)",
  },
  {
    Description:
      "개발자를 꿈꾸는 사람이라면, 반드시 거쳐야 할 온라인 코딩캠프라고 생각합니다! 현업에 바로 투입 가능한 주니어 양성과 각자의 주특기 프레임워크부터, 여러 기능들을 다뤄보고 만들어 볼 수 있는 클론 코딩, 여러 파트분들과 협업 할 수 있는 프로젝트까지! 정말 매력적인 항해99 입니다.",
    Title: "이바울 | 쏘카",
  },
  {
    Description:
      "개발자와 디자이너가 팀을 이루어서, 실제 서비스 런칭을 준비하는 과정이 매력적입니다. 협업을 통해 개발자로서 성장할 수 있는 기회이길 기대합니다 :)",
    Title: "고준희 | 뱅크샐러드",
  },
  {
    Description:
      "항해99를 완주하여 컴퓨터 공학 지식과 더불어 프로그래밍 실전 경험까지 갖추신다면 신입 개발자로서 어디서든 환영 받으실 것 같습니다. 응원합니다. 현업에서 기다리고 있겠습니다!",
    Title: "김동석 | 핀테크 유니콘 T사",
  },
  {
    Description:
      '항해99는 프로그래밍을 단순히 공부하는 것이 아닌, 이해를 바탕으로 프로그래밍을 "할 줄 알게" 되는 코스입니다. 쉽고 작은 것부터 실전 프로젝트까지, 직접 만들어 보는건 반드시 좋은 경험이 되리라 믿습니다.',
    Title: "이현호 | 와디즈",
  },
  {
    Description:
      "단순히 포트폴리오를 만들고 끝나는 것이 아니라, 실제로 사용되는 서비스를 개발한다는 것은 정말 다양한 경험치를 쌓을 수 있는 좋은 기회라고 생각합니다. 항해99는 개발자가 되기 위한 종합선물세트입니다.",
    Title: "JUNG | IT대기업 N사",
  },
];

export const CarouselPrevArrowSVGProps = {
  dataDirection: "prev",
  width: "49",
  height: "50",
  viewBox: "0 0 49 50",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
};

export const CarouselPrevArrowPathProps = {
  dataDirection: "prev",
  d: "m30.792 13-12 12 12 12",
  strokeWidth: "2",
};

export const CarouselNextArrowSVGProps = {
  dataDirection: "next",
  width: "48",
  height: "50",
  viewBox: "0 0 48 50",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
};

export const CarouselNextArrowPathProps = {
  dataDirection: "next",
  d: "m18 13 12 12-12 12",
  strokeWidth: "2",
};

export const landingJoinedCompaniesData1 = [
  {
    title: "원OO, 20대 후반, 정보통신공학과 졸",
    img: finda,
    desc1: "핀다\n데이터기반 금융상품 비교 추천 서비스 (174억+ 투자)",
    desc2: `"프론트엔드 분야로 커리어를 시작하고 싶어서 지원하게 되었고 99일동안 크루원들과 같이 열정적으로 몰입해서 개발하며 엄청나게 성장할 수 있었던 좋은 경험이였습니다."`,
  },
  {
    title: "홍OO, 20대 초반, 고졸",
    img: megazone,
    desc1: "메가존 클라우드\n기업용 클라우드 인프라 구축 서비스 (2380억+ 투자)",
    desc2: `"원하는 걸 만들면서 능동적으로 성장했고 같은 꿈을 가진 좋은 사람들과 협업하는 소중한 경험을 했습니다. 99일 정말 길지도 짧지도 않은 시간이지만 몰입한다면 원하는 바 이룰 수 있을 거라 확신합니다."`,
  },
  {
    title: "손OO, 30대 초반, 이공계열 졸",
    img: yeogi,
    desc1: "여기어때\n(기업가치 3000억+)",
    desc2: `"개발에 흥미는 생겼지만 정확히 무엇을 어떻게 해야할지 모르는 분들께 추천하고 싶습니다. 디자이너, 개발자 간의 소통을 통한 협업을 경험할 수 있고, 실제 배포 후 광고를 통해 짧게 나마 마케팅도 다뤄본 경험이 현업의 큰 그림을 볼 수 있게 해줬습니다."`,
  },
  {
    title: "박OO, 20대 후반, 컴퓨터공학과 졸",
    img: hyundai,
    desc1: "현대오토에버,\n현대차 그룹 IT 전문 서비스기업",
    desc2: `"취업 준비에 앞서 제 강점을 확실히 다지기 위해 항해에 참여했고, 항해에서 단기간에 급성장하여 원하는 회사에 취업할 수 있었습니다. 특히, 원하는 기능을 구현하기 위해 열정 넘치는 팀원들과 함께 밤새 노력했던 것이 기억에 남습니다. 99일 동안 멘토님들과 팀원들이 함께하며 팀 커뮤니케이션 능력도 다질 수 있는 기회였습니다"`,
  },
];

export const landingJoinedCompaniesData2 = [
  {
    title: "채OO, 20대 후반, 컴퓨터공학과 졸",
    img: taling,
    desc1: "탈잉,\n온·오프라인 통합 클래스 플랫폼",
    desc2: `"전공자이지만 졸업 이후 뒤늦게 개발자를 목표한 저에게 단기간에 실력을 끌어올리고 결과물을 만들 필요가 있었습니다. 그런 면에서 항해는 딱 맞는 곳이었고, 매일 몰입하며 협업을 통한 진정한 개발자로써 첫 걸음을 떼었다고 생각합니다."`,
  },
  {
    title: "송OO, 20대 중반, 고졸",
    img: cafe24,
    desc1: "카페24,\n글로벌 전자상거래 플랫폼 (1320억+ 투자)",
    desc2: `"항해를 통해 개발자로 취업하기 위해 저는 머리도 밀고 고시원에서 99일간 온라인으로 몰입했습니다. 최종 프로젝트를 두고 튜터님들의 날카로운 피드백을 받았던 것이 큰 도움이 됐습니다. 기획부터 배포까지 최고의 동료들과 밤새 고민하고 성장했던 시간들은 두고두고 기억에 남을 것입니다."`,
  },
  {
    title: "유OO님, 20대 중반, 상경계열 졸",
    img: balaan,
    desc1: "발란,\n명품 이커머스 스타트업 (100억+ 투자)",
    desc2: `"비전공자라 고민하면서 신청했던 항해99였지만, 99일 동안 같은 꿈을 가진 열정적인 크루원분들과 함께라 후회 없는 선택이었습니다. 같이 일하고 싶은 동료까지 얻게 된 소중한 경험!"`,
  },
  {
    title: "금OO님, 20대 후반, 자연계열 졸",
    img: alpaca,
    desc1: "매스아시아(알파카),\n마이크로 모빌리티 스타트업",
    desc2: `"졸업 직후 "뭐 해먹고 살지?"라는 막막함을 갖던 시점에, 개발자를 해야겠다는 생각 하나만으로 항해를 만나서 빠르게 취업했습니다. 여러 프로젝트를 시도해 봤지만, 기획부터 배포를 하는 과정이 성공적으로 이루어진 경험은 항해가 처음이었는데요. 팀원들과 고민을 통해 계획들을 이행해 나갔던 의미 있는 시간이었습니다."`,
  },
  {
    title: "전OO님, 20대 후반, 자연계열 졸",
    img: tutoring,
    desc1: "튜터링,\n에듀테크 스타트업",
    desc2: `"배우고 복습만 하던 개발자 지망생에서 생각하고 응용하여 만들어내는 개발자가 되었습니다. 항해에서 변화의 계기를 가지게 되어 좋았습니다."`,
  },
  {
    title: "이OO님, 20대 후반, 예체능계열 졸",
    img: newplay,
    desc1: "뉴플로이,\nHR테크 스타트업 (95억+ 투자)",
    desc2: `"몰입할 수 있는 환경에서 여러 분야의 멘토들을 통해 신뢰 높은 서포트를 받았습니다. 특히 실전 프로젝트를 통해 개인의 역량 또한 충분히 성장하였음을 느꼈고, 인생에서 뜻 깊은 순간 중 하나가 되었습니다.\n잘할 수 있을까 걱정하던 저도 정말 많이 성장하였습니다. 고민하지 말고 항해하세요!"`,
  },
];

export const landingHanghaeDifferenceData = [
  {
    number: 1,
    title: "교/강사 대신 멘토, 자기주도적 학습",
    desc: "현업의 개발자들은 시니어에게 방향성에 대한 가이드는 받지만, 결국 스스로 학습하고 성장합니다.",
    desc2:
      "항해99의 멘토 또한 학습 방향성을 제시하고 피드백과 코칭을 제공합니다. 이를 통해 교육생은 스스로 성장하는 방법을 터득할 수 있습니다.",
  },
  {
    number: 2,
    title: "디자이너 협업, 마케팅 집행까지",
    desc: "현업의 개발자들은 디자이너와의 협업을 통해 고객이 존재하는 살아있는 프로덕트를 만듭니다.",
    desc2:
      "항해99 실전프로젝트 또한 디자이너와 함께 프로덕트를 만들고, 마케팅비와 교육을 지원받아 살아있는 프로덕트를 만듭니다.",
  },
  {
    number: 3,
    title: "14주간의 팀프로젝트 경험",
    desc: "가장 위대한 개발자도 협업합니다. 현대의 프로그래머는 혼자가 아닌 팀으로 일하게 되는데요.",
    desc2:
      "항해에서는 99일의 기간 내내 각기 다른 여러 사람들과의 협업을 경험하게 되고, 이를 통해 팀플레이어로 거듭날 수 있습니다.",
  },
  {
    number: 4,
    title: "하나의 주특기에 능숙한 개발자",
    desc: "풀스택 개발자 채용공고, 생각보다 많지 않습니다. 현업에서는 결국 하나를 깊게 다루게 됩니다.",
    desc2:
      "항해99는 의도적으로 풀스택 교육을 하지 않습니다. 제한된 시간 내에, 하나의 언어를 깊이 이해하고 능숙해지세요.",
  },
  {
    number: 5,
    title: "99일, 주 100시간 몰입을 통한 압축 성장",
    desc: "산술적인 시간을 뛰어넘어 압축 성장하세요. 남들의 500일과 같은 시간이 될 것입니다.",
    desc2:
      "일반적인 직장인들은 주 40시간 일합니다. 주 100시간은 2.5배이죠. 눈 떠서 잠 들기까지 코딩만 생각하기에, 이 시간의 밀도는 더더욱 진합니다.",
  },
];

export const landingPortData1 = [
  "항해99의 자체 채용 플랫폼인 Port99를 통해 이력서 하나로 간편 지원이 가능하도록 취업을 연계해 드리고 있습니다. 최대 19개의 협력사에서 면접 제안을 받아본 수료생도 있으며, 대다수는 복수 기업에 합격해 내가 가장 성장할 수 있는 회사를 선택하여 팀에 합류합니다.",
];
export const landingPortData2 = [
  "함께하고 있는 400개 이상의 협력사 외에도, 카카오벤처스, 위벤처스, 매쉬업엔젤스, KB인베스트먼트, DS자산운용, 캡스톤파트너스, 스마일게이트인베스트먼트등 국내 최고의 벤처케피탈(VC)이 보유한 패밀리사와 채용 파트너십을 맺고 있습니다.",
];

export const landingMentorData = [
  {
    img: sparta_mentor,
    logo: sparta,
    mentor: "남병관 멘토",
    job: "스파르타코딩클럽 CTO",
    career: "전) 삼성 SDS 개발자",
    desc: "전체 커리큘럼 멘토",
  },
  {
    img: ecube_mentor,
    logo: ecubelabs,
    mentor: "강승현 멘토",
    job: "이큐브랩 Product Leader",
    desc: "Node.js 커리큘럼 멘토",
  },
  {
    img: sleek_mentor,
    logo: sleek,
    mentor: "임민영 멘토",
    job: "Sleek CTO",
    career: "전) Devsisters 프론트엔드 개발자",
    desc: "React 커리큘럼 멘토",
  },
  {
    img: ecommerce_mentor,
    logoText: "이커머스 쇼핑몰 C사",
    mentor: "이OO 멘토",
    job: "시니어 서버 엔지니어",
    desc: "Spring 커리큘럼 멘토",
  },
  {
    img: baemin_mentor,
    logoText: "배달앱 B사",
    mentor: "남OO 멘토",
    job: "프론트엔드 개발자",
    desc: "실전 프로젝트 담임 멘토, 커리어 멘토",
  },
  {
    img: oneul_mentor,
    logo: oneul,
    mentor: "이석범 멘토",
    job: "백엔드 개발자",
    desc: "실전 프로젝트 담임 멘토, 커리어 멘토",
  },
  {
    img: sap_mentor,
    logo: sap,
    mentor: "김명인 멘토",
    job: "Tech Senior Consultant",
    desc: "실전 프로젝트 담임 멘토",
  },
  {
    img: james_mentor,
    mentor: "James 멘토",
    logoText: "국내 Top 가상자산 플랫폼",
    job: "백엔드 개발자",
    career: "전) NHN 커머스 서비스 개발자",
    desc: "실전 프로젝트 담임 멘토",
  },
];

export const landingMentorEnding =
  `이외에도 <span>총 70여 명의 시니어 개발자</span>가 프로젝트의 처음과 끝을 함께하며,\n` +
  `<b>현업에서 인정 받는 항해 출신 개발자<b/>가 각 주특기의 담당 학습 코치로 상주합니다.`;

export const landingCurriculumCheckData = [
  "14주 99일 과정",
  "주 6일(월~토)",
  "오전 9시 ~ 오후 9시",
];

export const landingCurriculumNavData = [
  { week: "0주차", chapter: "사전 스터디" },
  { week: "1주차", chapter: "풀스택 미니 프로젝트" },
  { week: "2주차", chapter: "프로그래밍 기초" },
  { week: "3-5주차", chapter: "주특기 입문/숙련/심화" },
  { week: "6주차", chapter: "미니 프로젝트" },
  { week: "7주차", chapter: "클론코딩" },
  { week: "8-13주차", chapter: "실전 프로젝트" },
  { week: "14주차", chapter: "지원하기" },
  { week: "수료 이후", chapter: "취업까지 함께" },
];

export const landingCurriculumGraphData = [
  {
    index: 0,
    week: "Chapter 0 | 사전 스터디",
    img: timeline0,
    desc: [
      "항해를 더욱 잘 적응하기 위해 필요한 기초 지식과 협업 능력을 쌓는 단계입니다.",
      "사전스터디는 5~6명으로 구성되며 담당 매니저가 웰컴-콜부터 진도체크 및 질의응답까지 함께합니다.",
      "합류시 제공되는 ‘웹개발 종합반’ 강의를 완주한 후, 팀원들과 함께 <b>토이 프로젝트</b>를 진행합니다. 강의에서 배운 CRUD\n기술을 기반으로 게시판 등 작은 기능을 완성하며 이 과정에서 팀원과 <b>‘기술적으로 소통하는 방법’</b>을 배웁니다.",
    ],

    button1: "최종 합격 후부터",
    button2: "개인",
  },
  {
    index: 1,
    week: "Chapter 1 | 풀스택 미니 프로젝트",
    img: timeline1,
    desc: [
      "동적 URL 할당, 로그인, 파일 업로드, SSR 등, 현업에서 자주 쓰이는 주요 기능들을 추가로 배웁니다.",
      "지금까지의 배움들을 바탕으로, 미니 팀 프로젝트를 진행합니다. 팀원들과 합심하여 문제를 해결하는 방식을 배워나감으\n로써, 개발자의 자질을 점차 갖추게 됩니다.",
      "프로젝트를 만들기 위한 자신감과 협업의 자질을 갖추게 됩니다.",
      "기간 중 동료 및 운영진 평가에 따라 남은 과정 수강이 어려울 수 있습니다.",
    ],
    button1: "1주",
    button2: "팀",
  },
  {
    index: 2,
    week: "Chapter 2 | 프로그래밍 기초",
    img: timeline2,
    desc: [
      "본격적으로 주특기를 공부하기 전, 필수 지식인 Java 또는 JavaScript를 공부하는 시간을 가집니다.",
      "주특기 언어에 대한 기본 지식과 필수 개념을 과제를 수행하며 습득할 수 있습니다.",
      "단순히 강의를 듣고 따라하는 것이 아닌, 알고리즘 기초 문제를 직접 풀어보며 내가 사용할 언어에 대해 더 잘 이해할 수\n있습니다.",
    ],
    button1: "1주",
    button2: "팀",
  },
  {
    index: 3,
    week: "Chapter 3 | 주특기 입문/숙련/심화",
    img: timeline3,
    desc: [
      "React, Node.js, Spring 중 택 1",
      "입문/숙련 과정을 통해 주특기 기술을 스스로 공부할 수 있는 단단한 토대를 마련하고, 심화 과정을 통해 현업 주니어 개\n발자에게 필요한 지식을 깊게 파고듭니다.",
      "이 과정에서 주특기로 된 개인 프로젝트 2개와 팀 프로젝트 1개를 진행하며, 내 기술 스택에 대한 능숙함과 자신감을 갖\n춥니다.",
    ],

    button1: "3주",
    button2: "팀",
  },
  {
    index: 4,
    week: "Chapter 4 | 미니 프로젝트",
    img: timeline4,
    desc: [
      "실전 프로젝트 전 마지막으로 팀워크와 커뮤니케이션, 그리고 기술을 가다듬는 단계입니다.",
      "주기적인 스프린트 실행 및 멘토와의 만남을 통해, 기술을 갈고닦으면서 동시에 팀으로 프로젝트를 진행하는 방법, 좋은\n개발자로 성장하기 위한 방법론을 터득합니다.",
      "개발자로의 성장 방향성이 조금 더 뚜렷해집니다. 지속적으로 성장할 수 있는 개발자, 좋은 커뮤니케이션을 갖춘 개발자\n가 되는 초석을 닦습니다.",
    ],
    button1: "1주",
    button2: "팀",
  },
  {
    index: 5,
    week: "Chapter 5 | 클론코딩",
    img: timeline5,
    desc: [
      "팀을 이뤄 만들어보고 싶었던 서비스를 100% 똑같이 구현해보면서, 주특기를 갈고닦습니다.",
      "참고할 주요 키워드와 가이드라인을 제공받되, 주도적 학습을 통해 어려운 실습 과정을 팀원들과 함께 이겨내며 끝까지 실행하는 법을 훈련합니다.",
      "서비스를 뜯어보고 이를 바탕으로 기획부터 개발까지 할 수 있는 사람, 팀으로 협업할 수 있는 사람이 됩니다.",
    ],
    button1: "1주",
    button2: "팀",
  },
  {
    index: 6,
    week: "Chapter 6 | 실전 프로젝트",
    img: timeline6,
    desc: [
      "실제 프로젝트를 만들어 런칭합니다. 고객을 모아 보고, 디자이너와 협업하고, 실제 마케팅비를 지원받아 집행합니다.",
      "MVP(Minimum Viable Product)를 개발하고, UT(사용성 테스트)를 통해 문제를 깊은 수준까지 파고 들며 제품을 개선\n하고 운영하는 과정을 반복합니다. 마지막으로, 항해99의 협력사들과 함께 프로젝트 발표회를 진행합니다.",
      "실제 고객의 목소리를 바탕으로 서비스를 개선해본, 주니어 같지 않은 주니어로 거듭납니다.",
    ],
    button1: "6주",
    button2: "팀",
  },
  {
    index: 7,
    week: "Chapter 7 | 지원하기",
    img: timeline7,
    desc: [
      "항해99의 가이드와 현업 탑클래스 개발자들의 세션을 바탕으로, 나의 강점을 잘 드러낼 수 있는 이력서와 포트폴리오 작\n성을 완료합니다.",
      "항해99의 협력사들에게 바로 지원하고, 협력사들은 이력서를 열람하여 채용 과정을 진행합니다. 이외의 플랫폼에서도\n희망하는 기업을 모색하여 지원합니다.",
      "항해99의 가이드와 튜터들의 피드백을 참고하여 신입 개발자에게 어떤 태도 및 역량을 기대하는지 이해하고, 모의 기술\n면접을 진행합니다.",
      "모의 기술면접 경험과 항해99의 가이드를 바탕으로, 실전 기술면접과 코딩테스트를 준비합니다.",
    ],
    button1: "1주",
    button2: "개인",
  },
  {
    index: 8,
    week: "Chapter 8 | 취업까지 함께",
    img: timeline8,
    desc: [
      "수료 직후, 취업을 대비하는 <b>‘스프린터스’</b> 활동에 2주간 참여합니다.",
      "현직 6~7년차 개발자 멘토들과 모의 면접을 진행하여 기술 면접을 준비합니다.",
      "유니콘 기업이 채용 공고시, 취업 대비 스터디에 참여하여 코딩테스트와 기술면접을 준비합니다. 해당 기업에 재직중인\n개발자의 취업 세션에 참여해 지원 경쟁력을 높입니다.",
    ],
    button1: "수료 후부터",
    button2: "개인",
  },
];

export const landingApplyData = [
  {
    img: clock,
    desc: "9 to 9, 99일",
  },
  {
    img: ship,
    desc: "50명씩 한 반 운영",
  },
  {
    img: calendar,
    desc: "주 6일 (월~토)",
  },
  {
    img: online,
    desc: "온라인 부트캠프",
  },
];

export const landingApplyRequirementData = [
  "팀으로 다수의 프로젝트를 진행하기에, 커뮤니케이션 능력과 협력적인 태도가 필요합니다.",
  "단기간에 압축 성장해야 하기에, 높은 수준의 열정과 반드시 개발자가 되고자 하는 의지가 필요합니다.",
  "취업을 목표로 한 과정이므로 20~30대 지원자분들을 모집합니다.",
];

export const landingApplyScheduleData = [
  {
    title: "지원서 접수",
    desc: "간단한 지원서를 작성합니다.",
  },
  {
    title: "온라인 면접",
    desc: "순차적으로 화상 인터뷰를 진행합니다.",
  },
  {
    title: "합격 & 사전준비",
    desc: "최종 합격 후, 0주차(사전강의, 사전스터디, 토이프로젝트)에\n참여하면서, 항해를 준비합니다.",
  },
  {
    title: "항해 시작",
    desc: "99일 간의 여정을 시작합니다.",
  },
];
