import styled from "@emotion/styled";
import { device } from "../../../../../styles/themes";
import { hhMainColor } from "../../../../../styles/themes/variables";

export const FirstApplyRound = styled.div`
  flex: 1;
  width: 320px;
  margin: 20px auto;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 25px 0 rgb(0 0 0 / 10%);
  padding: 20px 0;
  @media ${device.desktop} {
    width: 604px;
    margin: 0 0 20px 0;
    padding: 45px 20px 50px 20px;
    display: grid;
    grid-template-rows: 1fr 0.3fr 0.1fr;
  }
`;

export const SecondApplyRound = styled(FirstApplyRound)``;

export const RoundInfo = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  @media ${device.desktop} {
    grid-template-columns: 1fr 1.5fr;
    padding: 0;
    width: 400px;
    margin: 0 auto;
    height: 90px;
  }
`;

export const Round = styled.div`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  @media ${device.desktop} {
    text-align: left;
    font-size: 18px;
  }
`;

export const RoundDetails = styled.div`
  margin: 15px 0 0 25px;

  @media ${device.desktop} {
    margin: -7px 0;
  }
`;

export const RoundDetail = styled.div`
  width: 100%;
  text-align: left;
  font-weight: 500;
  margin-bottom: 2px;
  @media ${device.desktop} {
    margin-bottom: 5px;
  }
`;

export const DetailTitle = styled.span`
  margin-right: 30px;
  font-family: "Pretendard";
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0.05px;
  text-align: left;
  font-weight: normal;
  @media ${device.desktop} {
    line-height: 1.4;
    letter-spacing: -0.56px;
  }
`;

export const DetailDesc = styled(DetailTitle)`
  @media ${device.desktop} {
    margin-right: 0;
  }
`;

export const Special = styled.span`
  color: ${hhMainColor};
  font-size: 12px;
  line-height: 140%;
  margin: 4px 0 0 29%;
  letter-spacing: 0.05px;
  @media ${device.desktop} {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    margin: 4px 0 0 34%;
    letter-spacing: -0.56px;
    text-align: left;
  }
`;

export const FirstRoundApply = styled.div`
  margin: 17px auto 0;
  width: 85%;
  @media ${device.desktop} {
    width: 400px;
    margin: 17px auto 0;
  }
`;

export const ApplyButton = styled.button`
  cursor: pointer;
  padding: 7px 30px 7px 30px;
  border: solid 2px ${hhMainColor};
  background-color: ${hhMainColor};
  color: white;
  font-weight: bold;
  font-size: 14px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  @media ${device.desktop} {
    padding: 10px 30px 10px 30px;
    font-size: 15px;
  }
`;

export const FirstApplyDue = styled.div`
  display: flex;
  margin-top: 5px;
  justify-content: center;
  font-weight: 300;
  align-items: baseline;
  @media ${device.desktop} {
    margin-top: 10px;
  }
`;

export const Text = styled.span`
  font-family: "Pretendard";
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: -0.48px;
  text-align: left;

  strong {
    font-weight: 400;
    color: ${hhMainColor};
  }

  @media ${device.desktop} {
    font-size: 14px;
    letter-spacing: -0.56px;
    line-height: 1.4;
    height: 14px;
  }
`;
