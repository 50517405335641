import styled from "@emotion/styled";
import { device } from "../../../../../styles/themes";
import { hhMainColor } from "../../../../../styles/themes/variables";

export const Wrapper = styled.section`
  background-color: #f1f1f1;
  padding: 40px 0;
  @media ${device.desktop} {
    padding: 80px 0;
  }
`;

export const Contents = styled.div`
  margin: 0 auto;
  max-width: 320px;
  @media ${device.desktop} {
    max-width: 1230px;
  }
`;

export const Title = styled.h2`
  margin: 0 0 12px;
  line-height: 1.45;
  font-size: 22px;
  text-align: center;
  @media ${device.desktop} {
    font-size: 31px;
    letter-spacing: 1px;
  }
`;

export const CheckPointWrapper = styled.div`
  color: #212529;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  @media ${device.desktop} {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 500px;
    font-size: 16px;
    letter-spacing: -1.12px;
    margin: 25px auto 40px auto;
  }
`;

export const CheckPoint = styled.span`
  display: flex;
  align-items: center;
  @media ${device.desktop} {
    margin-right: 24px;
    align-items: end;
  }
`;

export const CheckIcon = styled.div`
  background-color: #000000;
  position: relative;
  border-radius: 20px;
  margin-right: 5px;
  padding: 7px;
  @media ${device.desktop} {
    padding: 10px;
    margin-right: 10px;
  }
`;

export const Text = styled.span`
  line-height: 1.6;
  letter-spacing: -0.48px;
  @media ${device.desktop} {
    line-height: 1.4;
    letter-spacing: -0.56px;
    margin-top: -3px;
  }
`;

export const GraphWrapper = styled.div`
  background-color: white;
  border-radius: 4px;
  margin: 30px 0 0;
  padding: 10px 0;
  @media ${device.desktop} {
    background-color: #f1f1f1;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    margin: auto;
    padding: 5px 0 10px;
  }
`;

export const GraphNav = styled.div`
  padding: 0;
`;

export const NavWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  margin: 0px 29px 0px 19px;
  display: grid;
  grid-template-columns: 9fr 1fr;
  @media ${device.desktop} {
    margin: 4px 0;
    padding-top: 12px;
    width: 285px;
  }
`;

export const Nav = styled.div<{ week: number; index: number }>`
  display: flex;
  align-items: center;
  @media ${device.desktop} {
    padding: 5px;
    border-radius: 4px;
    background-color: ${(props) => (props.week === props.index ? "white" : "")};
  }
`;

export const Week = styled.span<{ week: number; index: number }>`
  color: ${(props) =>
    props.week === props.index ? `${hhMainColor}` : "#1d1e1f"};
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: -0.48px;
  width: 25%;
  padding: 10px 0px 10px 10px;
  font-weight: 600;
  @media ${device.desktop} {
    letter-spacing: -0.64px;
    font-size: 14px;
    width: 26%;
    padding: 5px;
    font-weight: 400;
  }
`;

export const Chapter = styled.span<{ week: number; index: number }>`
  color: ${(props) => (props.week === props.index ? `${hhMainColor}` : "")};
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.05px;
  font-weight: bold;
  line-height: 1;
  @media ${device.desktop} {
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: -0.56px;
    margin-left: 16px;
    letter-spacing: -0.6px;
  }
`;

export const Arrow = styled.div<{ week: number; index: number }>`
  position: relative;
  padding-left: 20px;
  width: 12px;
  height: ${(props) => (props.week === props.index ? "7px" : "12px")};
  transform: ${(props) =>
    props.week === props.index ? "rotate(180deg)" : "rotate(90deg)"};
  @media ${device.desktop} {
    display: none;
  }
`;

export const Hr = styled.hr<{ week: number; index: number }>`
  width: 115%;
  opacity: 0.5;
  border: 1px solid #f1f1f1;
  padding-left: 20px;
  margin: 10px 0;
  display: ${(props) =>
    props.week === props.index || props.index === 8 ? "none" : ""};
  @media ${device.desktop} {
    display: none;
  }
`;

export const Graph = styled.div<{ mapIndex?: number; dataIndex?: number }>`
  display: ${(props) =>
    props.mapIndex !== props.dataIndex ? "none" : "inherit"};
  @media ${device.desktop} {
    display: block;
    width: 900px;
    height: 580px;
    box-shadow: 0 1px 12px 0 rgb(0 0 0 / 6%);
    background-color: #ffffff;
    padding-top: 60px;
    position: relative;
    border-radius: 4px;
  }
`;

export const Header = styled.div`
  margin: auto auto 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 84%;
  @media ${device.desktop} {
    width: 83%;
    margin-bottom: 0;
  }
`;

export const ChapterWeek = styled.p`
  margin: 0;
  width: fit-content;
  line-height: 1;
  font-weight: bold;
  letter-spacing: -0.48px;
  font-size: 12px;
  white-space: nowrap;
  @media ${device.desktop} {
    color: ${hhMainColor};
    font-size: 20px;
    width: 545px;
    white-space: inherit;
  }
`;

export const ChapterInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 144px;
  @media ${device.desktop} {
    width: 160px;
  }
`;

export const Weeks = styled.span<{ type: string }>`
  color: ${(props) =>
    props.type.includes("팀") || props.type.includes("개인")
      ? "white"
      : `${hhMainColor}`};
  background-color: ${(props) =>
    props.type.includes("팀") || props.type.includes("개인")
      ? `${hhMainColor}`
      : ""};
  font-size: 12px;
  border-radius: 4px;
  border: solid 0.5px ${hhMainColor};
  letter-spacing: -0.36px;
  /*
  padding: ${(props) =>
    props.type.includes("팀") ? "4.5px 4.5px 3.5px;" : "4.5px 1.5px 3.5px"};
  */
  padding: 4px 8px;
  margin-left: ${(props) =>
    props.type.includes("팀") || props.type.includes("개인") ? "3px" : ""};
  font-weight: 500;
  @media ${device.desktop} {
    font-size: 15px;
    letter-spacing: -0.45px;
    padding: 4.5px 6.5px 3.5px;
    line-height: 1;
`;

export const WeekImg = styled.div`
  display: none;
  @media ${device.desktop} {
    display: inherit;
    position: relative;
    justify-content: center;
    margin: 40px auto 0;
    height: 118px;
  }
`;

export const ChapterDesc = styled.ul`
  width: 84%;
  margin: 16px auto 0;
  padding-bottom: 16px;
  padding-inline-start: 17px;
  @media ${device.desktop} {
    width: 80%;
    margin: 26px auto 0;
  }
`;

export const Desc = styled.li<{ desc?: string }>`
  font-size: 12px;
  line-height: 1.8;
  font-weight: normal;
  letter-spacing: -0.42px;
  @media ${device.desktop} {
    //white-space: pre-wrap;
    font-size: 16px;
    letter-spacing: -0.52px;
    line-height: 1.9;
  }
`;
