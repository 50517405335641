export const fontPretendard = "Pretendard";
export const fontAlarmClock = "AlarmClock";
export const fontSpoqaHanSans = "SpoqaHanSans";
export const fontNanumHandWritingDaughter = "NanumHandWritingDaughter";
export const fontAritaBuri = "Arita-buri";
export const fontGmarketSans = "GmarketSans";
export const fontNanumDaSiSiJagHae = "Nanum-DaSiSiJagHae";

export const hhMainColor = "#f50000";
export const hhBlueColor = "#202540";
export const hhBlackColor = "#1d1e1f";
