import * as S from "./LandingPageBestPartnersOrganism.style";
import Image from "next/image";
import arrow_next_black from "/static/images/icons/arrow_next_black.svg";
import { MutableRefObject, useRef, useState } from "react";
import { useInnerWidth } from "../../../../businessLogics/util-browser";

export let partnerRef: MutableRefObject<any>;

export default function LandingPageBestPartnersOrganism() {
  partnerRef = useRef(null);
  const [showMoreLogos, setShowMoreLogs] = useState(false);
  const innerWidth = useInnerWidth();
  return (
    <S.Wrapper id="협력사" ref={partnerRef}>
      <S.Title>최고의 협력사와 함께 항해합니다.</S.Title>
      <S.Desc>
        글로벌 또는 국내에서 최고의 성과를 내고 있는 <br />
        400개 이상의 기업들과 함께 합니다.
      </S.Desc>
      <S.CompanyLogos showMoreLogos={showMoreLogos}>
        <Image
          src={
            innerWidth > 1024
              ? `${process.env.CDN_BASE_URL}/hanghae99/landing_company_logos.png`
              : `${process.env.CDN_BASE_URL}/hanghae99/landing_company_logos_mobile.png`
          }
          layout="fill"
          objectFit="cover"
          objectPosition="top"
        />
      </S.CompanyLogos>
      {!showMoreLogos && (
        <S.ShowMoreButton onClick={() => setShowMoreLogs(true)}>
          협력사 더보기
          <S.DropdownIcon showMoreLogos={showMoreLogos}>
            <Image src={arrow_next_black} />
          </S.DropdownIcon>
        </S.ShowMoreButton>
      )}
    </S.Wrapper>
  );
}
