import styled from "@emotion/styled";
import { device } from "../../../../../styles/themes";
import { hhMainColor } from "../../../../../styles/themes/variables";

export const Wrapper = styled.div``;

export const BackgroundImg = styled.div`
  position: relative;
  background-color: #1d1e1f;
  height: 574px;
  @media ${device.desktop} {
    height: 709px;
  }
`;

export const Intro = styled.section`
  margin: 0 auto;
  color: white;

  @media ${device.desktop} {
    max-width: 1230px;
  }
`;

export const IntroContents = styled.section`
  position: absolute;
  top: 20%;
  text-align: center;
  left: 0;
  right: 0;
  @media ${device.desktop} {
    top: 18%;
    text-align: inherit;
    left: inherit;
    right: inherit;
  }
`;

export const IntroTitle = styled.h1`
  font-size: 24px;
  line-height: 1.45;
  margin-bottom: 22px;
  @media ${device.desktop} {
    font-size: 40px;
    line-height: inherit;
    margin-bottom: 32px;
  }
`;

export const IntroDesc = styled.p`
  font-size: 14px;
  line-height: 1.6;
  @media ${device.desktop} {
    font-size: 16px;
    margin-top: 0;
  }
`;

export const IntroButton = styled.button`
  color: white;
  cursor: pointer;
  margin-top: 15px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  background-color: ${hhMainColor};
  padding: 10px 38px;
  @media ${device.desktop} {
    padding: 11.5px 47.5px;
  }
`;
