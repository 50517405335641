import * as S from "./LandingPageApplyOraganism.style";
import {
  landingApplyData,
  landingApplyRequirementData,
  landingApplyScheduleData,
} from "../../../../models/landing/landing.models";
import Image from "next/image";
import { useInnerWidth } from "../../../../businessLogics/util-browser";
import { MutableRefObject, useRef } from "react";
import LandingPageApplyRounds from "../../../molecules/LandingPageMolcules/LandingPageApplyRounds";

export let applyRef: MutableRefObject<any>;
export default function LandingPageApplyOraganism() {
  applyRef = useRef(null);

  const innerWidth = useInnerWidth();
  return (
    <S.Wrapper id="모집일정" ref={applyRef}>
      <S.Contents>
        <S.Title>모집합니다.</S.Title>
        <S.Desc>
          좋은 개발자로 거듭나기 위한 99일 간의 여정을 시작하세요.
        </S.Desc>
        <S.CardWrapper>
          <S.ApplyInfoWrapper>
            <S.ApplyInfo>
              <S.LeftInfo>
                <S.Header>진행 방법</S.Header>
                <S.ProcessDescWrapper>
                  {landingApplyData.map((desc) => (
                    <S.ProcessDesc key={desc.desc}>
                      <Image
                        src={desc.img}
                        width={innerWidth > 1024 ? 28 : 20}
                        height={innerWidth > 1024 ? 28 : 20}
                      />
                      <S.DescDetail>{desc.desc}</S.DescDetail>
                    </S.ProcessDesc>
                  ))}
                </S.ProcessDescWrapper>
                <S.Requirement>
                  <S.NarrowHeader>지원자격</S.NarrowHeader>
                  <S.ListWrapper>
                    {landingApplyRequirementData.map((requirement) => (
                      <S.List key={requirement}>{requirement}</S.List>
                    ))}
                  </S.ListWrapper>
                </S.Requirement>
              </S.LeftInfo>
              <S.RightInfo>
                <S.Header>상세 일정</S.Header>
                <S.RightListWrapper>
                  {landingApplyScheduleData.map((schedule) => (
                    <S.RightLists key={schedule.title}>
                      <S.RightList>{schedule.title}</S.RightList>
                      <S.RightListDesc>{schedule.desc}</S.RightListDesc>
                    </S.RightLists>
                  ))}
                </S.RightListWrapper>
              </S.RightInfo>
            </S.ApplyInfo>
          </S.ApplyInfoWrapper>
          <S.ApplyRounds>
            <LandingPageApplyRounds />
          </S.ApplyRounds>
        </S.CardWrapper>
      </S.Contents>
    </S.Wrapper>
  );
}
