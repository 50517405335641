import styled from "@emotion/styled";
import { hhBlackColor } from "../../../../../styles/themes/variables";
import { device } from "../../../../../styles/themes";

export const Wrapper = styled.section`
  background-color: ${hhBlackColor};
`;

export const Contents = styled.div`
  max-width: 100%;
  padding: 40px 0;
  @media ${device.desktop} {
    padding: 80px 0;
    max-width: 1230px;
    margin: auto;
  }
`;

export const Title = styled.h2`
  color: white;
  margin: 0 20px 24px;
  font-size: 22px;
  line-height: 1.45;
  @media ${device.desktop} {
    font-size: 31px;
    br {
      display: none;
    }
  }
`;

export const Carousel = styled.div`
  @media ${device.desktop} {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
export const Arrow = styled.div<{ isActive: boolean }>`
  display: none;

  @media ${device.desktop} {
    display: inherit;
  }

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 78px;
  height: 50%;
  padding: 0 15px;

  cursor: ${(props) => (props.isActive ? "pointer" : "default")};

  text-align: center;

  :before,
  :after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }
`;

export const ArrowSVG = styled.svg``;

export const ArrowPath = styled.path<{ isActive: boolean }>`
  stroke: ${(props) => (props.isActive ? "white" : "#888888")};
`;

export const InterviewWrapper = styled.div`
  @media ${device.desktop} {
    overflow-x: hidden;
    position: relative;
    width: 1050px;
    height: 414px;
  }
`;

export const Interviews = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transform-style: preserve-3d;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  transition: left 1s;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  div:first-of-type {
    margin-left: 20px;
  }
  div:last-of-type {
    margin-right: 20px;
  }
  @media ${device.desktop} {
    position: absolute;
    left: 0;
    display: flex;
    div:first-of-type {
      margin-left: 0;
    }
    div:last-of-type {
      margin-right: 0;
    }
  }
`;

export const Interview = styled.div`
  flex: 0 0 auto;
  width: 264px;
  height: 380px;
  margin-right: 16px;
  margin-left: 0;
  word-break: keep-all;
  display: flex;
  flex-direction: column;
  scroll-snap-align: center;
  justify-content: flex-end;
  @media ${device.desktop} {
    width: 334px;
    height: 414px;
    margin-right: 24px;
  }
  h3 {
    color: white;
    white-space: pre-wrap;
    font-size: 16px;
    line-height: 1.5;
    margin: 24px 0 16px;
    @media ${device.desktop} {
      font-size: 18px;
    }
  }
  p {
    color: white;
    font-weight: 100;
    line-height: 1.4;
    margin: 0 0 20px;
    font-size: 13px;
    @media ${device.desktop} {
      font-size: 16px;
      margin: 0;
    }
  }
  div {
    margin-top: auto;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  button {
    width: 80%;
    cursor: pointer;
    align-items: center;
    padding: 10px 15px 10px 26px;
    background-color: #e4e4e4;
    border: none;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
    color: black;
  }
`;
