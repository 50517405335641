import styled from "@emotion/styled";
import { device } from "../../../../../styles/themes";

export const Wrapper = styled.section`
  padding: 40px 0;
  text-align: center;
  max-width: 320px;
  margin: 0 auto;
  @media ${device.desktop} {
    max-width: 1230px;
    padding: 80px 0;
  }
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 22px;
  line-height: 1.45;
  @media ${device.desktop} {
    font-size: 31px;
    letter-spacing: 1px;
  }
`;

export const Desc = styled.p`
  line-height: 1.6;
  letter-spacing: -0.42px;
  font-size: 14px;
  margin: 0;
  font-weight: 300;
  @media ${device.desktop} {
    font-size: 16px;
    letter-spacing: -1.12px;
    color: #212529;
    br {
      display: none;
    }
  }
`;

export const CompanyLogos = styled.div<{ showMoreLogos: boolean }>`
  position: relative;
  height: ${(props) => (props.showMoreLogos ? "506px" : "260px")};
  -webkit-mask-image: ${(props) =>
    props.showMoreLogos
      ? ""
      : "linear-gradient(to top, transparent, black 20%"});
  margin:20px 0 10px;
  @media ${device.desktop} {

    height: ${(props) => (props.showMoreLogos ? "538px" : "365px")};
    
  }
`;

export const ShowMoreButton = styled.button`
  display: flex;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #e4e4e4;
  justify-content: center;
  align-items: center;
  width: 152px;
  font-size: 13px;
  font-weight: bold;
  height: 35px;
  margin: 0 auto;
  color: black;
  @media ${device.desktop} {
    width: 170px;
    height: 42px;
    font-size: 15px;
  }
`;

export const DropdownIcon = styled.div<{ showMoreLogos: boolean }>`
  width: 15px;
  margin-left: 5px;
  padding-top: 3px;
  transform: rotate(90deg);
`;
